import { ApolloClient, InMemoryCache } from '@apollo/client';
import config from '../config';
import possibleTypes from './possibleTypes.json';

const setupClient = (preview = false) => {
  let endpoint = 'https://graphql.datocms.com';

  if (config.keys.dato.environment) {
    endpoint += `/environments/${config.keys.dato.environment}`;
  }

  const client = new ApolloClient({
    uri: endpoint,
    cache: new InMemoryCache({ possibleTypes }),
    defaultOptions: {
      query: { fetchPolicy: 'no-cache' },
    },
    connectToDevTools: config.isDeveloping,
    headers: {
      'X-Environment': process.env.DATO_SANDBOX_ENVIRONMENT || '',
      authorization: `Bearer ${config.keys.dato.token}`,
      ...(preview ? { 'X-Include-Drafts': 'true' } : ''),
    },
  });

  return client;
};

// TODO: Read best practices about exporting apollo clients with dato and Next and how that affects caching
const DatoClient = setupClient();
const DatoPreviewClient = setupClient(true);

export const getDatoClient = (preview = false) => (preview ? DatoPreviewClient : DatoClient);
